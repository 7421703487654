<template>
    <footer class="footer -type-2 bg-dark-3 text-white bgg ps-0">
        <div class="container">
          <div class="pb-20">
            <div class="row y-gap-40 xl:justify-start">
              <div class="col-xl-4 col-lg-6">
                <img src="images/White AeroMiles.me.png" style="width: 240px;" alt="image">
  
                <div class="row pt-0 y-gap-30 justify-between pt-30">
                  <div class="col-sm-6">
                    <div class="text-14">Customer Support</div>
                    <a href="#" class="text-18 fw-500 mt-5">+(281) 509-1505</a>
                  </div>
  
                  <div class="col-sm-5">
                    <div class="text-14">Email us</div>
                    <!-- <div class="text-14">Need live support?</div> -->
                    <a href="#" class="text-18 fw-500 mt-5">fly@aeromiles.me</a>
                  </div>
                </div>
              </div>
  
              <div class="col-lg-8">
                <div class="row y-gap-30">
                  <div class="col-md-6">
                    <!-- <h5 class="text-16 fw-500 mb-30">Company</h5> -->
                    <div class="mt-20 less-marg">
                      <div class="d-flex">
                        <div class="d-flex y-gap-5 flex-column me-4">
                          <router-link to="/">
                            Home
                          </router-link>
                          <router-link to="/all-enquiries">
                            Travel Quest
                          </router-link>
                          <router-link to="/blogs">
                            Blogs
                          </router-link>
                          <router-link to="/about">
                            About us
                          </router-link>
                        </div>
                        <div class="d-flex y-gap-5 flex-column">
                          <router-link to="/contact">
                            Contact
                          </router-link>
                          <router-link to="/terms-conditions">
                            Terms of use
                          </router-link>
                          <router-link to="/privacy-policy">
                            Privacy policy
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mt-20 less-marg">
                      <h5 class="text-16 text-white fw-500 mb-10">Follow us on social media</h5>
      
                      <div class="d-flex x-gap-20 items-center">
                        <a href="#"><i class="fa-brands fa-facebook"></i></a>
                        <a href="#"><i class="fa-brands fa-x-twitter"></i></a>
                        <a href="#"><i class="fa-brands fa-instagram"></i></a>
                        <a href="#"><i class="fa-brands fa-linkedin-in"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="py-20 border-top-white-15">
            <div class="row justify-center items-center y-gap-10">
              <div class="col-auto">
                <div class="row x-gap-30 y-gap-10">
                  <div class="col-auto">
                    <div class="text-14">
                      © 2024 AeroMiles.me All rights reserved.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
</template>
  
<script>
export default {
name: 'FooterPage',
props: {

}
}
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bgg{
  background-color: #2c625c !important;
}
@media screen and (max-width: 576px) {
  .less-marg{
    margin-top: 0px !important;
  }
}
</style>
  